import * as React from 'react';
import * as ReactDOM from 'react-dom';
import MainNav from './MainNav';

export default (mainNavContainer: Element) => {
    const yearsContainer = mainNavContainer.getElementsByClassName(
        'main-nav_years',
    ).item(0) as HTMLSpanElement;

    const upcomingEventsContainer = mainNavContainer.getElementsByClassName(
        'main-nav_upcoming-events',
    ).item(0) as HTMLSpanElement;

    ReactDOM.render(<MainNav
        years={JSON.parse(yearsContainer.dataset.json)}
        upcomingEvents={JSON.parse(upcomingEventsContainer.dataset.json)}
    />, mainNavContainer);
};
