export { default as AcademicCapIcon } from './AcademicCapIcon.js'
export { default as AdjustmentsIcon } from './AdjustmentsIcon.js'
export { default as AnnotationIcon } from './AnnotationIcon.js'
export { default as ArchiveIcon } from './ArchiveIcon.js'
export { default as ArrowCircleDownIcon } from './ArrowCircleDownIcon.js'
export { default as ArrowCircleLeftIcon } from './ArrowCircleLeftIcon.js'
export { default as ArrowCircleRightIcon } from './ArrowCircleRightIcon.js'
export { default as ArrowCircleUpIcon } from './ArrowCircleUpIcon.js'
export { default as ArrowDownIcon } from './ArrowDownIcon.js'
export { default as ArrowLeftIcon } from './ArrowLeftIcon.js'
export { default as ArrowNarrowDownIcon } from './ArrowNarrowDownIcon.js'
export { default as ArrowNarrowLeftIcon } from './ArrowNarrowLeftIcon.js'
export { default as ArrowNarrowRightIcon } from './ArrowNarrowRightIcon.js'
export { default as ArrowNarrowUpIcon } from './ArrowNarrowUpIcon.js'
export { default as ArrowRightIcon } from './ArrowRightIcon.js'
export { default as ArrowSmDownIcon } from './ArrowSmDownIcon.js'
export { default as ArrowSmLeftIcon } from './ArrowSmLeftIcon.js'
export { default as ArrowSmRightIcon } from './ArrowSmRightIcon.js'
export { default as ArrowSmUpIcon } from './ArrowSmUpIcon.js'
export { default as ArrowUpIcon } from './ArrowUpIcon.js'
export { default as ArrowsExpandIcon } from './ArrowsExpandIcon.js'
export { default as AtSymbolIcon } from './AtSymbolIcon.js'
export { default as BackspaceIcon } from './BackspaceIcon.js'
export { default as BadgeCheckIcon } from './BadgeCheckIcon.js'
export { default as BanIcon } from './BanIcon.js'
export { default as BeakerIcon } from './BeakerIcon.js'
export { default as BellIcon } from './BellIcon.js'
export { default as BookOpenIcon } from './BookOpenIcon.js'
export { default as BookmarkAltIcon } from './BookmarkAltIcon.js'
export { default as BookmarkIcon } from './BookmarkIcon.js'
export { default as BriefcaseIcon } from './BriefcaseIcon.js'
export { default as CakeIcon } from './CakeIcon.js'
export { default as CalculatorIcon } from './CalculatorIcon.js'
export { default as CalendarIcon } from './CalendarIcon.js'
export { default as CameraIcon } from './CameraIcon.js'
export { default as CashIcon } from './CashIcon.js'
export { default as ChartBarIcon } from './ChartBarIcon.js'
export { default as ChartPieIcon } from './ChartPieIcon.js'
export { default as ChartSquareBarIcon } from './ChartSquareBarIcon.js'
export { default as ChatAlt2Icon } from './ChatAlt2Icon.js'
export { default as ChatAltIcon } from './ChatAltIcon.js'
export { default as ChatIcon } from './ChatIcon.js'
export { default as CheckCircleIcon } from './CheckCircleIcon.js'
export { default as CheckIcon } from './CheckIcon.js'
export { default as ChevronDoubleDownIcon } from './ChevronDoubleDownIcon.js'
export { default as ChevronDoubleLeftIcon } from './ChevronDoubleLeftIcon.js'
export { default as ChevronDoubleRightIcon } from './ChevronDoubleRightIcon.js'
export { default as ChevronDoubleUpIcon } from './ChevronDoubleUpIcon.js'
export { default as ChevronDownIcon } from './ChevronDownIcon.js'
export { default as ChevronLeftIcon } from './ChevronLeftIcon.js'
export { default as ChevronRightIcon } from './ChevronRightIcon.js'
export { default as ChevronUpIcon } from './ChevronUpIcon.js'
export { default as ChipIcon } from './ChipIcon.js'
export { default as ClipboardCheckIcon } from './ClipboardCheckIcon.js'
export { default as ClipboardCopyIcon } from './ClipboardCopyIcon.js'
export { default as ClipboardListIcon } from './ClipboardListIcon.js'
export { default as ClipboardIcon } from './ClipboardIcon.js'
export { default as ClockIcon } from './ClockIcon.js'
export { default as CloudDownloadIcon } from './CloudDownloadIcon.js'
export { default as CloudUploadIcon } from './CloudUploadIcon.js'
export { default as CloudIcon } from './CloudIcon.js'
export { default as CodeIcon } from './CodeIcon.js'
export { default as CogIcon } from './CogIcon.js'
export { default as CollectionIcon } from './CollectionIcon.js'
export { default as ColorSwatchIcon } from './ColorSwatchIcon.js'
export { default as CreditCardIcon } from './CreditCardIcon.js'
export { default as CubeTransparentIcon } from './CubeTransparentIcon.js'
export { default as CubeIcon } from './CubeIcon.js'
export { default as CurrencyBangladeshiIcon } from './CurrencyBangladeshiIcon.js'
export { default as CurrencyDollarIcon } from './CurrencyDollarIcon.js'
export { default as CurrencyEuroIcon } from './CurrencyEuroIcon.js'
export { default as CurrencyPoundIcon } from './CurrencyPoundIcon.js'
export { default as CurrencyRupeeIcon } from './CurrencyRupeeIcon.js'
export { default as CurrencyYenIcon } from './CurrencyYenIcon.js'
export { default as CursorClickIcon } from './CursorClickIcon.js'
export { default as DatabaseIcon } from './DatabaseIcon.js'
export { default as DesktopComputerIcon } from './DesktopComputerIcon.js'
export { default as DeviceMobileIcon } from './DeviceMobileIcon.js'
export { default as DeviceTabletIcon } from './DeviceTabletIcon.js'
export { default as DocumentAddIcon } from './DocumentAddIcon.js'
export { default as DocumentDownloadIcon } from './DocumentDownloadIcon.js'
export { default as DocumentDuplicateIcon } from './DocumentDuplicateIcon.js'
export { default as DocumentRemoveIcon } from './DocumentRemoveIcon.js'
export { default as DocumentReportIcon } from './DocumentReportIcon.js'
export { default as DocumentSearchIcon } from './DocumentSearchIcon.js'
export { default as DocumentTextIcon } from './DocumentTextIcon.js'
export { default as DocumentIcon } from './DocumentIcon.js'
export { default as DotsCircleHorizontalIcon } from './DotsCircleHorizontalIcon.js'
export { default as DotsHorizontalIcon } from './DotsHorizontalIcon.js'
export { default as DotsVerticalIcon } from './DotsVerticalIcon.js'
export { default as DownloadIcon } from './DownloadIcon.js'
export { default as DuplicateIcon } from './DuplicateIcon.js'
export { default as EmojiHappyIcon } from './EmojiHappyIcon.js'
export { default as EmojiSadIcon } from './EmojiSadIcon.js'
export { default as ExclamationCircleIcon } from './ExclamationCircleIcon.js'
export { default as ExclamationIcon } from './ExclamationIcon.js'
export { default as ExternalLinkIcon } from './ExternalLinkIcon.js'
export { default as EyeOffIcon } from './EyeOffIcon.js'
export { default as EyeIcon } from './EyeIcon.js'
export { default as FastForwardIcon } from './FastForwardIcon.js'
export { default as FilmIcon } from './FilmIcon.js'
export { default as FilterIcon } from './FilterIcon.js'
export { default as FingerPrintIcon } from './FingerPrintIcon.js'
export { default as FireIcon } from './FireIcon.js'
export { default as FlagIcon } from './FlagIcon.js'
export { default as FolderAddIcon } from './FolderAddIcon.js'
export { default as FolderDownloadIcon } from './FolderDownloadIcon.js'
export { default as FolderOpenIcon } from './FolderOpenIcon.js'
export { default as FolderRemoveIcon } from './FolderRemoveIcon.js'
export { default as FolderIcon } from './FolderIcon.js'
export { default as GiftIcon } from './GiftIcon.js'
export { default as GlobeAltIcon } from './GlobeAltIcon.js'
export { default as GlobeIcon } from './GlobeIcon.js'
export { default as HandIcon } from './HandIcon.js'
export { default as HashtagIcon } from './HashtagIcon.js'
export { default as HeartIcon } from './HeartIcon.js'
export { default as HomeIcon } from './HomeIcon.js'
export { default as IdentificationIcon } from './IdentificationIcon.js'
export { default as InboxInIcon } from './InboxInIcon.js'
export { default as InboxIcon } from './InboxIcon.js'
export { default as InformationCircleIcon } from './InformationCircleIcon.js'
export { default as KeyIcon } from './KeyIcon.js'
export { default as LibraryIcon } from './LibraryIcon.js'
export { default as LightBulbIcon } from './LightBulbIcon.js'
export { default as LightningBoltIcon } from './LightningBoltIcon.js'
export { default as LinkIcon } from './LinkIcon.js'
export { default as LocationMarkerIcon } from './LocationMarkerIcon.js'
export { default as LockClosedIcon } from './LockClosedIcon.js'
export { default as LockOpenIcon } from './LockOpenIcon.js'
export { default as LoginIcon } from './LoginIcon.js'
export { default as LogoutIcon } from './LogoutIcon.js'
export { default as MailOpenIcon } from './MailOpenIcon.js'
export { default as MailIcon } from './MailIcon.js'
export { default as MapIcon } from './MapIcon.js'
export { default as MenuAlt1Icon } from './MenuAlt1Icon.js'
export { default as MenuAlt2Icon } from './MenuAlt2Icon.js'
export { default as MenuAlt3Icon } from './MenuAlt3Icon.js'
export { default as MenuAlt4Icon } from './MenuAlt4Icon.js'
export { default as MenuIcon } from './MenuIcon.js'
export { default as MicrophoneIcon } from './MicrophoneIcon.js'
export { default as MinusCircleIcon } from './MinusCircleIcon.js'
export { default as MinusSmIcon } from './MinusSmIcon.js'
export { default as MinusIcon } from './MinusIcon.js'
export { default as MoonIcon } from './MoonIcon.js'
export { default as MusicNoteIcon } from './MusicNoteIcon.js'
export { default as NewspaperIcon } from './NewspaperIcon.js'
export { default as OfficeBuildingIcon } from './OfficeBuildingIcon.js'
export { default as PaperAirplaneIcon } from './PaperAirplaneIcon.js'
export { default as PaperClipIcon } from './PaperClipIcon.js'
export { default as PauseIcon } from './PauseIcon.js'
export { default as PencilAltIcon } from './PencilAltIcon.js'
export { default as PencilIcon } from './PencilIcon.js'
export { default as PhoneIncomingIcon } from './PhoneIncomingIcon.js'
export { default as PhoneMissedCallIcon } from './PhoneMissedCallIcon.js'
export { default as PhoneOutgoingIcon } from './PhoneOutgoingIcon.js'
export { default as PhoneIcon } from './PhoneIcon.js'
export { default as PhotographIcon } from './PhotographIcon.js'
export { default as PlayIcon } from './PlayIcon.js'
export { default as PlusCircleIcon } from './PlusCircleIcon.js'
export { default as PlusSmIcon } from './PlusSmIcon.js'
export { default as PlusIcon } from './PlusIcon.js'
export { default as PresentationChartBarIcon } from './PresentationChartBarIcon.js'
export { default as PresentationChartLineIcon } from './PresentationChartLineIcon.js'
export { default as PrinterIcon } from './PrinterIcon.js'
export { default as PuzzleIcon } from './PuzzleIcon.js'
export { default as QrcodeIcon } from './QrcodeIcon.js'
export { default as QuestionMarkCircleIcon } from './QuestionMarkCircleIcon.js'
export { default as ReceiptRefundIcon } from './ReceiptRefundIcon.js'
export { default as ReceiptTaxIcon } from './ReceiptTaxIcon.js'
export { default as RefreshIcon } from './RefreshIcon.js'
export { default as ReplyIcon } from './ReplyIcon.js'
export { default as RewindIcon } from './RewindIcon.js'
export { default as RssIcon } from './RssIcon.js'
export { default as SaveAsIcon } from './SaveAsIcon.js'
export { default as SaveIcon } from './SaveIcon.js'
export { default as ScaleIcon } from './ScaleIcon.js'
export { default as ScissorsIcon } from './ScissorsIcon.js'
export { default as SearchCircleIcon } from './SearchCircleIcon.js'
export { default as SearchIcon } from './SearchIcon.js'
export { default as SelectorIcon } from './SelectorIcon.js'
export { default as ServerIcon } from './ServerIcon.js'
export { default as ShareIcon } from './ShareIcon.js'
export { default as ShieldCheckIcon } from './ShieldCheckIcon.js'
export { default as ShieldExclamationIcon } from './ShieldExclamationIcon.js'
export { default as ShoppingBagIcon } from './ShoppingBagIcon.js'
export { default as ShoppingCartIcon } from './ShoppingCartIcon.js'
export { default as SortAscendingIcon } from './SortAscendingIcon.js'
export { default as SortDescendingIcon } from './SortDescendingIcon.js'
export { default as SparklesIcon } from './SparklesIcon.js'
export { default as SpeakerphoneIcon } from './SpeakerphoneIcon.js'
export { default as StarIcon } from './StarIcon.js'
export { default as StatusOfflineIcon } from './StatusOfflineIcon.js'
export { default as StatusOnlineIcon } from './StatusOnlineIcon.js'
export { default as StopIcon } from './StopIcon.js'
export { default as SunIcon } from './SunIcon.js'
export { default as SupportIcon } from './SupportIcon.js'
export { default as SwitchHorizontalIcon } from './SwitchHorizontalIcon.js'
export { default as SwitchVerticalIcon } from './SwitchVerticalIcon.js'
export { default as TableIcon } from './TableIcon.js'
export { default as TagIcon } from './TagIcon.js'
export { default as TemplateIcon } from './TemplateIcon.js'
export { default as TerminalIcon } from './TerminalIcon.js'
export { default as ThumbDownIcon } from './ThumbDownIcon.js'
export { default as ThumbUpIcon } from './ThumbUpIcon.js'
export { default as TicketIcon } from './TicketIcon.js'
export { default as TranslateIcon } from './TranslateIcon.js'
export { default as TrashIcon } from './TrashIcon.js'
export { default as TrendingDownIcon } from './TrendingDownIcon.js'
export { default as TrendingUpIcon } from './TrendingUpIcon.js'
export { default as TruckIcon } from './TruckIcon.js'
export { default as UploadIcon } from './UploadIcon.js'
export { default as UserAddIcon } from './UserAddIcon.js'
export { default as UserCircleIcon } from './UserCircleIcon.js'
export { default as UserGroupIcon } from './UserGroupIcon.js'
export { default as UserRemoveIcon } from './UserRemoveIcon.js'
export { default as UserIcon } from './UserIcon.js'
export { default as UsersIcon } from './UsersIcon.js'
export { default as VariableIcon } from './VariableIcon.js'
export { default as VideoCameraIcon } from './VideoCameraIcon.js'
export { default as ViewBoardsIcon } from './ViewBoardsIcon.js'
export { default as ViewGridAddIcon } from './ViewGridAddIcon.js'
export { default as ViewGridIcon } from './ViewGridIcon.js'
export { default as ViewListIcon } from './ViewListIcon.js'
export { default as VolumeOffIcon } from './VolumeOffIcon.js'
export { default as VolumeUpIcon } from './VolumeUpIcon.js'
export { default as WifiIcon } from './WifiIcon.js'
export { default as XCircleIcon } from './XCircleIcon.js'
export { default as XIcon } from './XIcon.js'
export { default as ZoomInIcon } from './ZoomInIcon.js'
export { default as ZoomOutIcon } from './ZoomOutIcon.js'