import React = require('react');
import { useCallback, useEffect, useState } from 'react';
import { XIcon } from '@heroicons/react/solid';
import SpeakerModel from './SpeakerModel';

const SpeakerJobAndCompanyCompany = (speaker: SpeakerModel) => {
    if (!speaker.jobTitle && !speaker.companyName) {
        return <></>;
    }

    if (speaker.jobTitle && !speaker.companyName) {
        return <>{speaker.jobTitle}</>;
    }

    if (speaker.jobTitle && speaker.companyName && !speaker.companyUrl) {
        return <>{speaker.jobTitle}, {speaker.companyName}</>;
    }

    if (speaker.jobTitle && speaker.companyName && speaker.companyUrl) {
        return <>
            {speaker.jobTitle}, <a
                className="text-eeconf-green underline"
                href={speaker.companyUrl}
            >
                {speaker.companyName}
            </a>
        </>;
    }

    return <></>;
};

const Speaker = ({ speakers }: {speakers: Array<SpeakerModel>}) => {
    const [activeId, setActiveId] = useState('');

    const close = (event: React.MouseEvent<HTMLAnchorElement>) => {
        event.preventDefault();

        setActiveId('');
    };

    const escape = useCallback((event: KeyboardEvent) => {
        if (event.code !== 'Escape') {
            return;
        }

        setActiveId('');
    }, [activeId, setActiveId]);

    useEffect(() => {
        document.addEventListener('keydown', escape);

        return () => {
            document.removeEventListener('keydown', escape);
        };
    }, [escape]);

    return <>
        {speakers.map((speaker) => {
            const isActive = activeId === speaker.uniqueId;

            const setActive = (event: React.MouseEvent<HTMLAnchorElement>) => {
                event.preventDefault();

                setActiveId(speaker.uniqueId);
            };

            if (!isActive) {
                return <a
                    onClick={setActive}
                    href="#0"
                    className={`${speaker.hrefClasses}`}
                >
                    <div dangerouslySetInnerHTML={{ __html: speaker.htmlContent }}></div>
                </a>;
            }

            return <div className={`${speaker.hrefClasses}`}>
                <div className="fixed z-50 inset-0 w-screen h-screen p-6 md:p-0 md:pl-14">
                    <span
                        onClick={close}
                        className="bg-white opacity-80 absolute z-40 inset-0 w-full h-full"
                    ></span>
                    <div className="bg-white shadow-xl border border-solid border-gray-200 w-full h-full p-4 overflow-y-scroll max-w-2xl mx-auto relative z-50 md:max-w-9xl md:mr-0 md:p-10">
                        <div className="mb-6 md:mb-0 md:absolute md:left-0 md:top-0 md:ml-8 md:mt-7">
                            <a
                                onClick={close}
                                href="#0"
                                className="text-gray-600 block ml-auto w-16 h-16"
                            >
                                <XIcon />
                            </a>
                        </div>
                        <div className="md:pl-24 md:text-left">
                            <div className="border border-solid border-gray-200">
                                <div className="md:inline-block md:align-top max-w-5xl">
                                    <div className="bg-gray-800 flex flex-col overflow-hidden aspect-4-3 relative">
                                        {speaker.imageIsSvg && <img
                                            className="object-cover object-center max-w-none w-inherit h-inherit aspect-4-3"
                                            src={speaker.imageSrc1x}
                                            alt={speaker.fullName}
                                        />}
                                        {!speaker.imageIsSvg && <img
                                            className="object-cover object-center max-w-none w-inherit h-inherit aspect-4-3"
                                            src={speaker.imageSrc1x}
                                            srcSet={`${speaker.imageSrc1x} 1x, ${speaker.imageSrc2x} 2x`}
                                            alt={speaker.fullName}
                                        />}
                                    </div>
                                </div>
                                <div className="m-4 md:m-12 md:inline-block md:align-top">
                                    <h2 className="font-bold">{speaker.fullName}</h2>
                                    <div className="md:mt-4">
                                        {SpeakerJobAndCompanyCompany(speaker)}
                                    </div>
                                </div>
                            </div>
                            {speaker.bio && <div className="text-left mt-10">
                                <h2 className="font-bold">Bio:</h2>
                                <div className="prose max-w-none text-2xl" dangerouslySetInnerHTML={{ __html: speaker.bio }} />
                            </div>}
                        </div>
                    </div>
                </div>
                <div dangerouslySetInnerHTML={{ __html: speaker.htmlContent }}></div>
            </div>;
        })}
    </>;
};

export default Speaker;
