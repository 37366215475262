import * as React from 'react';
import * as ReactDOM from 'react-dom';
import SpeakerModel from './SpeakerModel';
import Speaker from './Speaker';

export default (speakersContainer: Element) => {
    const speakerElements = speakersContainer.querySelectorAll(
        '[ref="speakers_speaker"]',
    );

    const speakers = Array.prototype.slice.call(speakerElements).map(
        (speakerElement: HTMLDivElement) => {
            const { innerHTML, classList, dataset } = speakerElement;

            return ({
                uniqueId: dataset.uniqueId,
                htmlContent: innerHTML,
                hrefClasses: classList.toString(),
                imageSrc1x: dataset.imageSrc1x,
                imageSrc2x: dataset.imageSrc2x,
                imageIsSvg: dataset.imageIsSvg === 'true',
                fullName: dataset.fullName,
                jobTitle: dataset.jobTitle,
                companyName: dataset.companyName,
                companyUrl: dataset.companyUrl,
                bio: dataset.bio,
            } as SpeakerModel);
        },
    );

    ReactDOM.render(<Speaker speakers={speakers} />, speakersContainer);
};
