import * as React from 'react';
import { useState } from 'react';
import { v4 as uuid } from 'uuid';
import {
    ChevronDownIcon, ChevronUpIcon, MenuIcon, XIcon,
} from '@heroicons/react/solid';
import Year from './Year';
import MainNavSource, { NavItem, SubNavType } from './MainNavSource';
import UpcomingEvent from './UpcomingEvent';
import Segments from '../Url/Segments';

const MainNav = ({
    years,
    upcomingEvents,
}: {
    years: Array<Year>,
    upcomingEvents: Array<UpcomingEvent>,
}) => {
    const [mobileMenuIsOpen, setMobileMenuIsOpen] = useState(false);

    const [openSubNav, setOpenSubNav] = useState('');

    const segments = new Segments();

    const hasChildren = (
        navItem: NavItem,
    ) => navItem.subNavType !== SubNavType.None;

    const setMobileMenuIsOpenFromClick = (
        event: React.MouseEvent<HTMLAnchorElement>,
    ) => {
        event.preventDefault();

        setMobileMenuIsOpen(true);
    };

    const setMobileMenuIsClosedFromClick = (
        event: React.MouseEvent<HTMLAnchorElement>,
    ) => {
        event.preventDefault();

        setMobileMenuIsOpen(false);
    };

    const setOpenSubNavFromClick = (
        event: React.MouseEvent<HTMLAnchorElement>,
    ) => {
        const element = event.currentTarget as HTMLAnchorElement;
        const itemHasChildren = element.dataset.hasChildren === 'true';

        if (!itemHasChildren) {
            return;
        }

        event.preventDefault();

        if (openSubNav === element.dataset.ref) {
            setOpenSubNav('');

            return;
        }

        setOpenSubNav(element.dataset.ref);
    };

    return (
        <>
            <a
                onClick={setMobileMenuIsOpenFromClick}
                href="#0"
                className="block w-20 h-20 text-white hover:text-eeconf-blue ml-auto pr-2 pt-1 lg:hidden"
            >
                <MenuIcon/>
            </a>
            {/* eslint-disable-next-line no-constant-condition */}
            <div className={`text-center absolute top-0 left-0 z-50 w-full ${mobileMenuIsOpen ? '' : 'hidden'} lg:block`}>
                <div className="block bg-white lg:bg-transparent m-6 lg:my-3 rounded-md lg:rounded-none shadow-lg lg:shadow-transparent border border-solid border-gray-300 lg:border-none">
                    <a
                        onClick={setMobileMenuIsClosedFromClick}
                        href="#0"
                        className="block w-20 h-20 text-eeconf-gray-dark hover:text-eeconf-green ml-auto pr-2 pt-1 lg:hidden"
                    >
                        <XIcon />
                    </a>
                    <ul className="block text-left lg:inline-block lg:mx-auto">
                        {MainNavSource.map((navItem, i) => {
                            const subNavIsOpen = openSubNav === navItem.href;
                            const itemHasChildren = hasChildren(navItem);
                            let segment = navItem.href;

                            if (segment.charAt(0) === '/') {
                                segment = segment.substring(1);
                            }

                            const isActive = segment === segments.segment(
                                0,
                            );

                            let anchorClasses = 'relative font-roboto font-bold block p-6';

                            if (isActive) {
                                anchorClasses += ' bg-eeconf-blue lg:bg-transparent lg:text-eeconf-green lg:hover:text-eeconf-green';
                            } else {
                                anchorClasses += ' text-eeconf-gray-dark lg:text-white lg:hover:text-eeconf-green hover:bg-eeconf-green lg:hover:bg-transparent hover:text-white';
                            }

                            if (i > 0) {
                                anchorClasses += ' border-t border-solid border-gray-300 lg:border-none xl:px-12';
                            }

                            return (
                                <li id={uuid()} className="block lg:inline-block lg:relative">
                                    <a
                                        onClick={setOpenSubNavFromClick}
                                        href={navItem.href}
                                        data-has-children={itemHasChildren}
                                        data-ref={navItem.href}
                                        className={anchorClasses}
                                    >
                                        <span className="inline-block align-middle">
                                            {navItem.content}
                                        </span>
                                        {itemHasChildren && <>
                                            <span className="absolute lg:relative lg:inline-block lg:align-middle right-0 top-0 w-20 lg:w-10 h-20 lg:h-10">
                                                {subNavIsOpen && <ChevronUpIcon/>}
                                                {!subNavIsOpen && <ChevronDownIcon/>}
                                            </span>
                                        </>}
                                    </a>
                                    {subNavIsOpen && <ul className="block pl-10 lg:pl-0 lg:absolute lg:top-full lg:left-0 lg:bg-white lg:w-80 lg:text-xl">
                                        {navItem.subNavType === SubNavType.Children && <>
                                            {navItem.children.map((subNavItem, subI) => (
                                                <li
                                                    id={uuid()}
                                                    className="block"
                                                >
                                                    <a
                                                        href={subNavItem.href}
                                                        className={`font-roboto font-bold lg:font-normal text-eeconf-gray-dark hover:bg-eeconf-green hover:text-white block p-6 ${subI === 0 ? '' : 'border-t border-solid border-gray-300'}`}
                                                    >
                                                        {subNavItem.content}
                                                    </a>
                                                </li>
                                            ))}
                                        </>}
                                        {navItem.subNavType === SubNavType.UpcomingEvents && <>
                                            {upcomingEvents.map((upcomingEvent, subI) => (
                                                <li
                                                    id={uuid()}
                                                    className="block"
                                                >
                                                    <a
                                                        href={upcomingEvent.linkHref}
                                                        className={`font-roboto font-bold lg:font-normal text-eeconf-gray-dark hover:bg-eeconf-green hover:text-white block p-6 ${subI === 0 ? '' : 'border-t border-solid border-gray-300'}`}
                                                    >
                                                        {upcomingEvent.linkContent}
                                                    </a>
                                                </li>
                                            ))}
                                        </>}
                                        {navItem.subNavType === SubNavType.PastConferences && <>
                                            {years.map((subYear, subI) => (
                                                <li
                                                    id={uuid()}
                                                    className="block"
                                                >
                                                    <a
                                                        href={subYear.href}
                                                        className={`font-roboto font-bold lg:font-normal text-eeconf-gray-dark hover:bg-eeconf-green hover:text-white block p-6 ${subI === 0 ? '' : 'border-t border-solid border-gray-300'}`}
                                                    >
                                                        {subYear.year}
                                                    </a>
                                                </li>
                                            ))}
                                        </>}
                                    </ul>}
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </div>
        </>
    );
};

export default MainNav;
