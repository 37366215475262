import * as React from 'react';
import * as ReactDOM from 'react-dom';
import PresentationDayModel from './PresentationDayModel';
import PresentationDays from './PresentationDays';

export default (presentationDaysContainer: Element) => {
    const dayElements = presentationDaysContainer.querySelectorAll(
        '[ref="days_day"]',
    );

    const days = Array.prototype.slice.call(dayElements).map(
        (dayElement: HTMLDivElement) => {
            const headingElement = dayElement.querySelector(
                '[ref="days_day-heading"]',
            ) as HTMLDivElement;

            const panelElement = dayElement.querySelector(
                '[ref="days_day-panel"]',
            ) as HTMLDivElement;

            const { classList, dataset } = dayElement;

            const { innerHTML: headingHtmlContent } = headingElement;

            const { innerHTML: panelHtmlContent } = panelElement;

            return ({
                containerClasses: classList.toString(),
                containerActiveClass: dataset.activeClass,
                headingHtmlContent,
                headingClasses: headingElement.classList.toString(),
                panelHtmlContent,
                panelClasses: panelElement.classList.toString(),
            } as PresentationDayModel);
        },
    );

    ReactDOM.render(
        <PresentationDays days={days} />,
        presentationDaysContainer,
    );
};
