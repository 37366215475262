// eslint-disable-next-line no-shadow
export enum SubNavType {
    None,
    Children,
    UpcomingEvents,
    PastConferences
}

export interface NavItem {
    href: string;
    content: string;
    children?: Array<NavItem>;
    subNavType?: SubNavType;
}

export default [
    {
        href: '/',
        content: 'Home',
        subNavType: SubNavType.None,
    },
    {
        href: '/sponsorship-opportunities',
        content: 'Sponsorships',
        subNavType: SubNavType.None,
    },
    {
        href: '#upcoming-ee-events',
        content: 'EE Events',
        subNavType: SubNavType.UpcomingEvents,
    },
    {
        href: '#get-involved',
        content: 'Get Involved',
        children: [
            {
                href: 'https://cfp.eeconf.com/',
                content: 'Become a Speaker',
            },
            {
                href: '/sponsorship-opportunities',
                content: 'Become a Sponsor',
            },
            {
                href: '/donate',
                content: 'Donate Now',
            },
        ],
        subNavType: SubNavType.Children,
    },
    {
        href: '#past-ee-conferences',
        content: 'Past Years',
        subNavType: SubNavType.PastConferences,
    },
] as Array<NavItem>;
