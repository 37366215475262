import { useState } from 'react';
import * as React from 'react';
import PresentationDayModel from './PresentationDayModel';

const PresentationDay = ({ day }: { day: PresentationDayModel }) => {
    const [isActive, setIsActive] = useState(false);

    let { containerClasses } = day;

    if (isActive) {
        containerClasses += ` ${day.containerActiveClass}`;
    }

    const toggleActive = () => {
        setIsActive(!isActive);
    };

    return <div className={containerClasses}>
        <div
            onClick={toggleActive}
            className={day.headingClasses}
            dangerouslySetInnerHTML={{ __html: day.headingHtmlContent }}
        ></div>
        <div
            className={day.panelClasses}
            dangerouslySetInnerHTML={{ __html: day.panelHtmlContent }}
        ></div>
    </div>;
};

export default PresentationDay;
