class Segments {
    fullPath: string;

    path: string;

    segments: Array<string>;

    constructor () {
        let path = window.location.pathname;

        this.fullPath = path;

        if (path.charAt(0) === '/') {
            path = path.substring(1);
        }

        this.path = path;

        this.segments = path.split('/');
    }

    segment (index: number): string {
        return this.segments[index] || '';
    }
}

export default Segments;
