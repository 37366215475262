import MainNavRender from './MainNav/MainNavRender';
import SpeakersRender from './Speakers/SpeakersRender';
import PresentationDaysRender from './PresentationDays/PresentationDaysRender';

const mainNav = document.getElementById('main-nav');
if (mainNav) {
    MainNavRender(mainNav);
}

const speakersContainers = document.querySelectorAll(
    '[ref="speakers"]',
);
speakersContainers.forEach((speakersContainer) => {
    SpeakersRender(speakersContainer);
});

const presentationDaysContainers = document.querySelectorAll(
    '[ref="days"]',
);
presentationDaysContainers.forEach(
    (presentationDaysContainer) => {
        PresentationDaysRender(presentationDaysContainer);
    },
);
